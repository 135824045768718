import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const TimeAgo = ({ createdAt }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const calculateTimeAgo = () => {
      try {
        const now = new Date();
        const createdDate = new Date(createdAt);

        if (isNaN(createdDate)) {
          throw new Error("Invalid date format");
        }

        const diff = now - createdDate;

        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        if (minutes > 0)
          return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
      } catch (error) {
        return "Invalid date";
      }
    };

    setTimeAgo(calculateTimeAgo());

    const intervalId = setInterval(() => {
      setTimeAgo(calculateTimeAgo());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [createdAt]);

  return <span>{timeAgo}</span>;
};

TimeAgo.propTypes = {
  createdAt: PropTypes.string,
};
export default TimeAgo;
